body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**Mixins**/
.mt50 {
  margin-top: 50px;
}
.p60 {
  padding: 80px 0;
}
.plr {
  padding-left: 0;
  padding-right: 0;
}
/**** Animation *****/
.transition {
  transition: all 0.3s ease;
}
.i-animat {
  transition-property: background-color;
  transition-duration: 0.07s;
  transition-timing-function: ease-in;
}
.clear {
  display: table;
  clear: both;
  content: " ";
}
/** Site colours **/
/**Brand colors**/
/** Media Queries **/
footer {
  padding: 60px 0;
  background: #ffffff;
  font-size: 14px;
}
footer h6 {
  color: #777777;
  margin-bottom: 10px;
  font-weight: 100;
}
footer .socials {
  font-weight: bold;
}
footer li {
  display: block;
  margin-bottom: 5px;
}
footer li a {
  color: #777777;
}
footer li .sm-twitter {
  color: #55acee;
}
footer li .sm-facebook {
  color: #3b5998;
}
footer li .sm-instagram {
  color: #e80088;
}
html,
body {
  position: relative;
  height: 100%;
  background: #fbfbfa !important;
}
body {
  color: #0d0e1e;
  font-family: "GT Walsheim", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 16px;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  vertical-align: baseline;
  background: #fbfbfa !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GT Walsheim", Helvetica, Arial, sans-serif;
  margin: 0;
  font-weight: 700;
}
a {
  text-decoration: none;
}
* input,
* textarea {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -khtml-user-select: initial;
  -ms-user-select: initial;
}
a {
  text-decoration: none !important;
  outline: none;
}
a:active {
  background-color: transparent;
}
a:hover {
  text-decoration: none;
}
strong {
  font-weight: 400;
}
ul,
li {
  padding: 0;
  margin: 0;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(126, 239, 104, 0);
  box-shadow: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
button:focus,
button:active {
  outline: none;
}
textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder,
.uneditable-input::placeholder {
  color: #d8d8d8;
  font-size: 12px;
}
input[type="radio"] {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 1.5px solid #68686c;
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

input[type="radio"]:checked {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border: 2px solid #ffa300;
  border-radius: 50%;
}

input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  border: 2px solid white;
}

input[type="radio"]::after {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 3.5px;
  right: 6px;
  bottom: 6px;
  background-color: #ffa300;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: none;
}

input[type="radio"]:checked::after {
  display: block;
} /* replace with your desired border color */

html {
  scroll-behavior: smooth;
}
.intl-tel-input .selected-flag {
  padding: 0 15px 0 0;
}
.intl-tel-input {
  width: 100%;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: transparent;
}
.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent;
}
.intl-tel-input .country-list .country-name,
.intl-tel-input .country-list .dial-code,
.intl-tel-input .country-list .flag-box {
  color: initial;
}
.badge span {
  position: absolute;
  width: 23.63px;
  /* height: 26.07px; */
  left: 1000.59px;
  top: 10.96px;

  font-family: "GT Walsheim";
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
}

.body table th tr td {
  background: white;
  color: white;
}

.kids-title p {
  display: flex;
  position: absolute;
  width: 71px;
  height: 29px;
  left: 31px;
  top: 26px;
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1f2023;
}

.children-list {
  height: 190px;
  overflow: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc #f5f5f5; /* For Firefox */
}

.children-list::-webkit-scrollbar {
  width: 6px;
}

.children-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.children-list::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.add-another-child-position {
  display: flex;
  width: 165px;
  height: 30px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 40px;
  /* margin-top: 270px; */
}

@media only screen and (min-width: 1024px) {
  .children-display {
    box-sizing: border-box;
    width: 365px;
    height: 390px;
    background: #ffffff;
    border: 0.25px solid #e1e1e1;
    box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
    border-radius: 4px;
    padding: 20px; /* Add padding to create space for the label */
    display: flex; /* Use flexbox to align children horizontally */
    flex-direction: column; /* Stack children vertically */
    margin-left: -430px;
    margin-top: 195px;
  }

  .add-child .child-avatar-vector {
    position: absolute;
    left: 70px;
    right: 46.65%;
    top: 10.44%;
    bottom: 187.42%;
    background: #171616;
  }

  .top-content {
    display: flex;
    width: 400px;
  }

  .users-title {
    display: flex;
    color: rgba(104, 104, 108, 0.6);
    font-family: GT Walsheim;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    width: 49px;
    height: 25px;
  }

  .users-title-pointer img {
    display: flex;
    width: 9px;
    height: 6px;
    margin-left: 20px;
  }

  .view-profile-title p {
    display: flex;
    width: 122px;
    height: 25px;
    font-family: GT Walsheim;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #1f2023;
    margin-left: 15px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .children-display {
    box-sizing: border-box;
    position: absolute;
    width: 365px;
    height: 390px;
    left: 45px;
    top: 450px;
    background: #ffffff;
    border: 0.25px solid #e1e1e1;
    box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
    border-radius: 4px;
    overflow: auto;
  }

  .users-title {
    display: flex;
    color: rgba(104, 104, 108, 0.6);
    font-family: GT Walsheim;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    width: 49px;
    height: 25px;
  }

  .users-title-pointer img {
    display: flex;
    width: 9px;
    height: 6px;
    margin-left: 20px;
  }

  .view-profile-title p {
    display: flex;
    width: 122px;
    height: 25px;
    font-family: GT Walsheim;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #1f2023;
    margin-left: 15px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .children-display {
    display: flex;
    box-sizing: border-box;
    width: 365px;
    height: 390px;
    left: 78px;
    top: 400px;
    background: #ffffff;
    border: 0.25px solid #e1e1e1;
    box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
    border-radius: 4px;
    overflow: auto;
  }

  .users-title {
    display: flex;
    color: rgba(104, 104, 108, 0.6);
    font-family: GT Walsheim;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    width: 49px;
    height: 25px;
  }

  .users-title-pointer img {
    display: flex;
    width: 9px;
    height: 6px;
    margin-left: 20px;
  }

  .view-profile-title p {
    display: flex;
    width: 122px;
    height: 25px;
    font-family: GT Walsheim;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #1f2023;
    margin-left: 15px;
    margin-top: 15px;
  }
}

.children-display-label {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1f2023;
  margin-bottom: 20px;
}

.children-bubble-position {
  /* padding: 8px; */
  color: #ffffff;
  border-bottom: 1px solid #e1e1e1;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background-color: #070b09;
}

.child-content {
  display: flex;
  align-items: center;
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Adjust the spacing between bubbles */
  margin-right: 10px;
}

.circle-inner {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the text takes the full height of the circle */
  width: 100%; /* Ensure the text takes the full width of the circle */
}
.child-label {
  white-space: nowrap;
  font-family: "GT Walsheim";
  font-size: 16px;
  line-height: 20px;
  color: #1f2023;
  align-items: center;
}

.child-session-completed-label {
  white-space: nowrap;
  font-family: "GT Walsheim";
  font-size: 14px;
  line-height: 25px;
  align-items: center;
  color: #68686c;
  opacity: 0.7;
}

.child-line-divider {
  width: 298px;
  height: 1px;
  border: 0.5px solid #d8dde6;
  margin-bottom: 10px;
}
.child-line-divider:last-child {
  margin-bottom: none;
}

.users-view-profile {
  box-sizing: border-box;
  position: absolute;
  width: 595px;
  height: 549px;
  left: 610px;
  top: 227px;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
}
.users-view-profile-delete {
  position: absolute;
  width: 101px;
  height: 12px;
  left: 469px;
  top: 59px;

  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #ef3d4b;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  /* letter-spacing: 1px; */
}
.users-view-profile-delete-button {
  position: absolute;
  left: -27%;
  top: -90%;
  bottom: 20%;
}
.users-view-profile-edit {
  box-sizing: border-box;
  position: absolute;
  width: 122px;
  height: 29px;
  left: 448px;
  top: 122px;
  border: 1px solid #00bb8d;
  filter: drop-shadow(0px 1px 4px rgba(13, 14, 30, 0.05));
  border-radius: 100px;
}

.users-view-profile-edit-text span {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #00bb8d;
  height: 28px;
  top: 142px;
  position: absolute;
  left: 13px;
  top: 0px;
}

.users-view-form-text p {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
}

.button-orange-wider {
  border: 1px solid #ffa300;
  background: #ffffff;
  color: #ffa300;
  font-size: 14px;
  border-radius: 70px;
  width: 220px;
}

.button-orange-wider:hover {
  color: #ffffff;
  background: #e69300;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
  cursor: pointer;
}

.button-orange {
  border: 1px solid #ffa300;
  background: #ffa300;
  color: #ffffff;
  font-size: 14px;
  border-radius: 50px;
}
.button-orange:hover {
  color: #ffffff;
  background: #e69300;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
  cursor: pointer;
}
.button-green {
  border: 1px solid #00bb8d;
  background: #00bb8d;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
}
.button-green:hover {
  background: #00a27a;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
.button-outline-green {
  border: 1px solid #00bb8d;
  background: transparent;
  color: #00bb8d;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
}
.button-outline-green:hover {
  color: #ffffff;
  background: #00a27a;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
.button-outline-grey {
  border: 1px solid #b9b6b3;
  background: transparent;
  color: #b9b6b3;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
}
.button-outline-grey:hover {
  color: #ffffff;
  background: #ada9a6;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
button:focus {
  outline: 0;
}
.top {
  width: 100%;
  height: 102px;
  background: #ffffff;
  margin-top: 1px;
  padding: 0 8%;
}
.top .filter {
  display: flex;
}
.top .filter .labels {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 50px;
}
.top .filter .labels span {
  color: #7b7b7e;
  font-size: 0.75rem;
}
.top .filter .labels .dropdown {
  margin-top: 7px;
}
.top .filter .labels .dropdown-toggle {
  background: #f4f4f6;
  border-radius: 50px;
  color: #68686c;
}
.top .filter .labels:last-child {
  margin-right: 0;
}
.top .filter .search input {
  border-radius: 20px;
  border: 1px solid #989899;
  color: #68686c;
  width: 250px;
  height: 30px;
  padding-left: 10px;
  padding: 6px;
  margin-top: 15px;
  margin-right: 16px;
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 150%;
}
.search:focus input {
  outline: none;
  border: 1px solid #a0d18c;
  color: #68686c;
}
.error-text {
  font-size: 10px;
  text-align: initial;
  color: #bc3e06;
}
.loader-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.loader-div img {
  height: 100px;
}
header {
  border-bottom: 1px solid rgba(216, 221, 230, 0.33);
  background: #ffffff;
}
header .navbar {
  padding: 0 8%;
}
header .navbar-nav.mobile {
  display: none;
}
header .nav-link {
  font-size: 18px;
  color: #68686c !important;
  text-transform: initial !important;
  margin-right: 40px;
}
header .nav-item:last-child .nav-link {
  margin-right: 0;
}
header .nav-link.active {
  color: #ffa300 !important;
}
header .nav {
  text-align: center;
}
header .nav .nav-item .nav-link {
  color: #68686c !important;
}
header .nav .nav-item .active {
  color: #ffa300 !important;
}
header .navbar-brand.mobile {
  display: none;
}
header .dropdown-menu {
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(33, 43, 54, 0.08),
    0px 0px 0px rgba(6, 44, 82, 0.1);
}
header .dropdown-menu .dropdown-item {
  text-transform: initial;
  font-size: 14px;
  padding: 8px 20px;
  cursor: pointer;
}
header .dropdown {
  cursor: pointer;
}
header .avi-text {
  height: 50px;
  border: 1px solid #d8dde6;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 0 12px;
}
header .avi-text img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid #dadada;
}
header .avi-text .name {
  display: inline-block;
}
header .avi-text p {
  font-size: 8px;
  color: #68686c;
  margin-bottom: 0;
}
header .avi-text h5 {
  font-size: 14px;
  color: #0d0e1e;
}
header .cart {
  height: 50px;
  width: 52px;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  margin-left: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  header {
    padding: 0 5%;
  }
  header .navbar-nav {
    margin-left: initial;
    margin-top: 10px;
  }
  header .navbar-brand img {
    margin: 0;
  }
  header .navbar-nav.desktop {
    display: none;
  }
  header .navbar-nav.mobile {
    display: initial;
  }
  header .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
  }
  header .avi-text {
    height: 40px;
    margin-top: -10px;
  }
  header .avi-text .nav-link {
    padding: 0 !important;
    margin-right: 0 !important;
  }
  header .avi-text img {
    margin-right: 10px;
  }
  header .navbar-toggler {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 529px) {
  header {
    padding: 0 5%;
  }
  header .no-mobile {
    display: none;
  }
  header .navbar-nav {
    margin-left: initial;
    margin-top: 10px;
  }
  header .navbar-brand.desktop {
    display: none;
  }
  header .navbar-brand img {
    margin: 0;
  }
  header .navbar-brand.mobile {
    display: initial;
  }
  header .navbar-nav.desktop {
    display: none;
  }
  header .navbar-nav.mobile {
    display: initial;
  }
  header .navbar-nav .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
  }
  header .avi-text {
    height: 40px;
    margin-top: -10px;
    margin-right: 0;
  }
  header .avi-text .nav-link {
    padding: 0 !important;
  }
  header .avi-text img {
    display: none;
  }
  header .navbar-toggler {
    margin-left: 10px;
  }
}
.text-title {
  font-family: "GT Walsheim";
  font-size: 22px;
  color: #1f2023;
}
.edit {
  cursor: pointer;
  margin-right: 10px;
}
.table-lists {
  width: 100%;
  background: #ffffff;
  border-top: 4px solid #ffb75d;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 25px 30px;
}
.table-lists .table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.table-lists .table-top h5 {
  font-size: 20px;
  color: #1f2023;
}
.table-lists .table-top button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  width: 158px;
  height: 42px;
  cursor: pointer;
}
.table-lists .body.empty {
  height: 363px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-lists .body.empty img {
  margin-bottom: 25px;
}
.table-lists .body.empty p {
  font-size: 14px;
  line-height: 25px;
  color: #68686c;
  opacity: 0.4;
}
.table-lists .body {
  width: 100%;
}
.table-lists .body table {
  width: 100%;
}
.table-lists .body table tr th {
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 1px;
  color: #68686c;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(216, 221, 230, 0.3);
  padding-right: 20px;
}
.table-lists .body table tr th:first-child {
  padding-left: 28px;
  border-radius: 15px 0 0 15px;
}
.table-lists .body table tr th:last-child {
  border-radius: 0 15px 15px 0;
}
.table-lists .body table tr td {
  font-size: 16px;
  line-height: 24px;
  color: #0d0e1e;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  border-bottom: 0.5px solid #d8dde6;
}
.table-lists .body table tr td .name-badge .badge {
  display: inline-block;
  margin-right: -5px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  font-size: 16px;
  color: #ffffff;
  background: #6c63ff;
  border: 1px solid #ffffff;
}
.table-lists .body table tr td .name-badge .badge div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.table-lists .body table tr td .view {
  font-size: 14px;
  color: #00bb8d;
  cursor: pointer;
}
.table-lists .body table tr td .view:hover {
  color: #00a27a;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
.table-lists .body table tr td:first-child {
  padding-left: 28px;
}
.table-lists .body table tr:last-child td {
  border-bottom: 0;
}
.table-lists .body .pagination-div {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.table-lists .body .pagination-div .pagination {
  display: flex;
}
.table-lists .body .pagination-div .pagination button {
  background: #eeeeee;
  border-radius: 2px;
  font-size: 14px;
  width: 62px;
  height: 40px;
  color: #cccccc;
  border: 1px solid #eeeeee;
  cursor: pointer;
}
.table-lists .body .pagination-div .pagination div {
  margin: 0 7px;
  background: #68686c;
  color: #ffffff;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal .modal-main {
  position: fixed;
  background: white;
  max-width: 85%;
  max-height: 90vh;
  height: auto;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15),
    0px 0px 0px rgba(63, 63, 68, 0.05);
  overflow-y: auto;
}
.modal .modal-main .close-icon {
  position: absolute;
  right: -10px;
  top: 5px;
  font-size: 24px;
  cursor: pointer;
  color: #c4c4c4;
}
.spinner-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.modal.display-block,
.spinner-modal.display-block {
  display: flex;
}
.modal.display-none,
.spinner-modal.display-none {
  display: none;
}
.popup {
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup img {
  margin-bottom: 32px;
}
.popup .text {
  color: #0d0e1e;
  text-align: center;
  line-height: 175.75%;
}
.popup .text span {
  font-weight: bold;
}
.popup select {
  color: #0d0e1e;
  text-align: center;
  line-height: 175.75%;
  font-size: 16px;
}
.popup .button-green {
  height: 42px;
  padding: 0 20px;
}
.create-profile {
  padding: 30px 45px;
  display: flex;
  width: 545px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create-profile .steps {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  height: 70px;
  width: 545px;
  margin: -20px -45px;
  margin-bottom: 50px;
}
.create-profile .steps .step {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-profile .steps .step .number-div {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #737373;
  color: #ffffff;
  font-weight: 500;
  height: 22px;
  width: 22px;
  margin-right: 8px;
}
.create-profile .steps .step.active {
  border-bottom: 4px solid #ffa300;
  color: #ffa300;
}
.create-profile .steps .step.active .number-div {
  background: #ffa300;
}
.create-profile .steps .step.previous {
  color: #00bb8d;
}
.create-profile .steps .step.previous .number-div {
  background: #00bb8d;
}
.create-profile .children {
  margin-top: -40px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f5f5f5;
}
.create-profile .children h5 {
  color: #c4c4c4;
  font-weight: normal;
  font-size: 12px;
}
.create-profile .children .children-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.create-profile .children .children-row .child {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-profile .children .children-row .child .badge {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  color: #ffffff;
  background: #6c63ff;
  font-size: 22.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.create-profile .children .children-row .child p {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #1f2023;
  font-weight: 600;
  margin-bottom: 5px;
}
.create-profile .children .children-row .child span {
  cursor: pointer;
}
.create-profile h3 {
  font-size: 18px;
  color: #0d0e1e;
  margin-top: 20px;
  margin-bottom: 50px;
  text-transform: capitalize;
}
.create-profile .form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin: 0;
}
.create-profile .select-label {
  border-bottom: 0;
}
.create-profile .select-div {
  display: flex;
  justify-content: space-between;
  background: rgba(244, 244, 246, 0.2);
  position: relative;
}
.create-profile .select-div select {
  border-radius: 0;
  width: 30%;
  height: 30px;
  margin: 0;
  padding-left: 0;
  background: none;
  border-bottom: 0.5px solid #68686c;
}
.create-profile .select-div select:focus {
  border-bottom: 0.5px solid #68686c;
}
.create-profile .select-div select:invalid {
  color: #e1e1e1;
}
.create-profile .select-div .error-text {
  bottom: -14px;
}
.create-profile label {
  width: 100%;
  height: 50px;
  position: initial;
  background: rgba(244, 244, 246, 0.2);
  border-bottom: 0.5px solid #68686c;
  margin-bottom: 40px;
  position: relative;
}
.create-profile label input,
.create-profile label select {
  margin: 0;
  padding: 0;
  border-bottom: 0;
}
.create-profile label input:focus,
.create-profile label select:focus {
  margin: 0;
  padding: 0;
  border-bottom: 0;
}
.create-profile label select {
  margin-left: -6px;
}
.create-profile label select:active,
.create-profile label select:focus {
  outline: 0;
}
.create-profile label .error-text {
  position: absolute;
  bottom: -32px;
}
.create-profile label.pre-text input {
  padding-left: 35px;
}
.create-profile label.pre-text .phone-code {
  position: absolute;
  bottom: -8.5px;
}
.create-profile .gender {
  border-bottom: 0;
}
.create-profile .gender .select-gender {
  display: flex;
  margin-top: 15px;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
.create-profile .gender .select-gender label {
  border-bottom: 0;
  width: auto;
}
.create-profile .gender .select-gender .container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin: 0;
  margin-bottom: 12px;
  margin-right: 30px;
  cursor: pointer;
  font-size: 16px;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
}
.create-profile .gender .select-gender .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.create-profile .gender .select-gender .checkmark {
  position: absolute;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #68686c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-profile .gender .select-gender .container:hover input ~ .checkmark {
  border: 2px solid #ccc;
}
.create-profile .gender .select-gender .container input:checked ~ .checkmark {
  border: 1px solid #ffa300;
}
.create-profile .gender .select-gender .checkmark:after {
  content: "";
  display: none;
}
.create-profile
  .gender
  .select-gender
  .container
  input:checked
  ~ .checkmark:after {
  display: block;
}
.create-profile .gender .select-gender .container .checkmark:after {
  background: #ffa300;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}
.create-profile .btn-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 10px;
}
.create-profile .btn-div button.button-outline-green {
  margin-top: -40px;
  margin-bottom: 40px;
}
.create-profile .btn-div .cancel {
  color: #ff6600;
  text-decoration: underline;
  margin-top: 15px;
  cursor: pointer;
}
.create-profile .btn-div img {
  height: 50px;
  position: absolute;
  top: -50px;
}
.create-profile .btn-div .error-text {
  position: absolute;
  font-size: 12px;
  top: -35px;
}
.create-profile .btn-div button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.create-profile .btn-div button:disabled:hover {
  background: transparent;
  color: #00bb8d;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
.create-profile .btn-div.row {
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
}
.navigation {
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 1.25rem;
}
.navigation .logo img {
  height: 30px;
}
.login-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  height: 100vh;
}
.login-container .side-bar {
  background-color: #162a33;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 529px) {
  .login-container .side-bar {
    display: none;
  }
}
.login-container .signin-form {
  background: #ffffff;
  margin-top: -70px;
  background: #fbfbfa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login-container .signin-form h2 {
  text-align: center;
  margin-bottom: 3.125rem;
}
.login-container .signin-form .login-form {
  background: #ffffff;
  min-width: 454px;
  border: 0.25px solid rgba(225, 225, 225, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 10px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 50px;
}
.login-container .signin-form .login-form .form-group {
  position: relative;
}
.login-container .signin-form .login-form .form-group .error-text {
  position: absolute;
  bottom: -24px;
}
.login-container .signin-form .loader-div {
  position: absolute;
  right: 0;
  left: 0;
}
.login-container .signin-form .loader-div img {
  height: 50px;
}
.create-account-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
}
.create-account-container .access {
  padding: 3.125rem;
}
.create-account-container .side-bar {
  position: relative;
  background-color: #162a33;
}
.create-account-container .side-bar .rocket {
  background-size: contain;
  background-repeat: no-repeat;
  height: 20rem;
  position: relative;
  left: 20%;
  top: 200px;
}
.create-account-container .signup-form {
  background: #fbfbfb;
  padding: 7.5rem;
  background: #fbfbfa;
}
.create-account-container .signup-form h2 {
  text-align: center;
  margin-bottom: 3.125rem;
}
.create-account-container .signup-form p {
  text-align: center;
  color: #68686c;
  font-size: 0.875rem;
}
.create-account-container .signup-form .nav {
  text-align: center;
}
.create-account-container .signup-form .nav .nav-item .nav-link {
  color: #68686c;
}
.create-account-container .signup-form .nav .nav-item .active {
  color: #ffa300;
}
.create-account-container .signup-form .create-account {
  padding: 1.875rem;
  background: #ffffff;
}
.create-account-container .signup-form .select-plan .plan {
  display: flex;
  background: #ffffff;
  padding: 1.25rem;
  border: 1px solid transparent;
  margin-bottom: 2rem;
}
.create-account-container .signup-form .select-plan .plan:hover {
  border: 1px solid #ffa300;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
  box-sizing: border-box;
}
.create-account-container .signup-form .select-plan .plan div {
  flex: 1 0 50%;
}
.create-account-container .signup-form .select-plan .plan .plan-details li {
  list-style: none;
  font-size: 0.75rem;
}
.create-account-container .signup-form .select-plan .plan .plan-details .cost {
  font-weight: bold;
  font-size: 2.125rem;
  margin-bottom: 1.5rem;
}
.create-account-container
  .signup-form
  .select-plan
  .plan
  .plan-details
  .cost
  span {
  font-size: 12px;
}
.create-account-container
  .signup-form
  .select-plan
  .plan
  .plan-details
  .toggle {
  font-size: 0;
}
.create-account-container
  .signup-form
  .select-plan
  .plan
  .plan-details
  .toggle
  input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.create-account-container
  .signup-form
  .select-plan
  .plan
  .plan-details
  .toggle
  input
  + label {
  margin: 0;
  padding: 5px 20px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border: 1px solid #ffa300;
  border-radius: 30px;
  background-color: #ffffff;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
  color: #ffa300;
}
.create-account-container
  .signup-form
  .select-plan
  .plan
  .plan-details
  .toggle
  input:hover
  + label {
  background: #ffa300;
  color: #ffffff;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
.create-account-container
  .signup-form
  .select-plan
  .plan
  .plan-details
  .toggle
  input:checked
  + label {
  background-color: #ffa300;
  color: #ffffff;
  border-color: #ffa300;
  z-index: 15;
}
.create-account-container .signup-form .select-plan .plan .plan-benefits li {
  line-height: 2;
}
.create-account-container .signup-form .select-plan .plan .plan-benefits #tick {
  line-height: 1.5rem;
}
form .form-group {
  position: relative;
}
form label {
  position: absolute;
  color: #68686c;
  font-size: 14px;
}
form input,
form textarea,
form select {
  border: none;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 0 5px;
  background: transparent;
}
form input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fbfbfa inset !important;
  -webkit-box-shadow: 0 0 0 1000px #fbfbfa inset !important;
}
form span {
  font-size: 0.75rem;
  color: #ffa300;
}
form span a {
  color: #30b08a;
}

form .button-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin: auto;
}

form button {
  border: 1px solid #ffa300;
  background: #ffa300;
  color: #ffffff;
  font-size: 14px;
  border-radius: 50px;
  height: 43px;
  padding: 0 40px;
  text-transform: uppercase;
}
form button:hover {
  color: #ffffff;
  background: #e69300;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
  cursor: pointer;
}
footer {
  background: #f3f4f5;
  padding: 1.875rem;
}
footer .logo-footer {
  display: inline-block;
}
footer .logo-footer img {
  height: 50px;
}
footer .social-links {
  float: right;
}
footer .social-links li {
  display: inline;
  margin-right: 10px;
}
footer .social-links li img {
  height: 20px;
  transition: all 0.2s ease-in;
  filter: grayscale(100%) contrast(50%);
}
footer .social-links li img:hover {
  filter: none;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
#dashboard {
  background: #fbfbfa;
}
#dashboard .dashboard-main {
  padding: 0 10%;
  padding-bottom: 207px;
}
#dashboard .dashboard-main .dashboard-hero {
  height: 310px;
  width: 100%;
  border-radius: 4px;
  margin-top: 44px;
  position: relative;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 1px 10px rgba(13, 14, 30, 0.05);
  padding: 50px 80px;
}
#dashboard .dashboard-main .dashboard-hero .img-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}
#dashboard .dashboard-main .dashboard-hero .img-2 {
  position: absolute;
  left: 0;
  bottom: 0;
}
#dashboard .dashboard-main .dashboard-hero .img-3 {
  position: absolute;
  right: 60px;
  bottom: 0;
}
#dashboard .dashboard-main .dashboard-hero .img-4 {
  position: absolute;
  right: 0;
  bottom: 0;
}
#dashboard .dashboard-main .dashboard-hero .male {
  position: absolute;
  right: 65px;
  top: 56px;
}
#dashboard .dashboard-main .dashboard-hero .female {
  position: absolute;
  top: 129px;
  right: 254px;
}
#dashboard .dashboard-main .dashboard-hero .text-div {
  width: 50%;
  position: relative;
}
#dashboard .dashboard-main .dashboard-hero .text-div h5 {
  font-size: 32px;
  line-height: normal;
  color: #1f2023;
  margin-bottom: 15px;
}
#dashboard .dashboard-main .dashboard-hero .text-div p {
  font-size: 14px;
  line-height: 25px;
  color: #68686c;
  margin-bottom: 13px;
}
#dashboard .dashboard-main .dashboard-hero .text-div button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  width: 251px;
  height: 48px;
  cursor: pointer;
  z-index: 2;
}
#dashboard .dashboard-main .dashboard-hero .text-div button img {
  margin-right: 12px;
}
#dashboard .dashboard-main .line {
  border: 0.5px solid rgba(216, 221, 230, 0.33);
  margin: 35px 0 26px;
}
#dashboard .dashboard-main .cards {
  display: flex;
  margin: 0 -9px;
}
#dashboard .dashboard-main .cards .card {
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
}
#dashboard .dashboard-main .cards .item {
  flex-grow: 1;
  padding: 9px;
  height: 141px;
}
#dashboard .dashboard-main .cards .card {
  height: 123px;
  padding: 25px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#dashboard .dashboard-main .cards .card h6 {
  font-size: 18px;
  color: #68686c;
  margin-bottom: 15px;
}
#dashboard .dashboard-main .cards .card p {
  font-size: 14px;
  line-height: 25px;
  color: #68686c;
  opacity: 0.4;
}
#dashboard .dashboard-main .upcoming-activities {
  width: 100%;
  background: #ffffff;
  margin-top: 20px;
  border-top: 4px solid #ffb75d;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 25px 30px;
}
#dashboard .dashboard-main .upcoming-activities .table-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
#dashboard .dashboard-main .upcoming-activities .table-top h5 {
  font-size: 20px;
  color: #1f2023;
}
#dashboard .dashboard-main .upcoming-activities .table-top button {
  background: #ffa300;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid #ffa300;
  border-radius: 31px;
  width: 158px;
  height: 42px;
  color: #ffffff;
  cursor: pointer;
}
#dashboard .dashboard-main .upcoming-activities .body.empty {
  height: 363px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#dashboard .dashboard-main .upcoming-activities .body.empty img {
  margin-bottom: 25px;
}
#dashboard .dashboard-main .upcoming-activities .body.empty p {
  font-size: 14px;
  line-height: 25px;
  color: #68686c;
  opacity: 0.4;
}
#dashboard .dashboard-main .upcoming-activities .body {
  width: 100%;
}
#dashboard .dashboard-main .upcoming-activities .body table {
  width: 100%;
}
#dashboard .dashboard-main .upcoming-activities .body table tr th {
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 1px;
  color: #68686c;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(216, 221, 230, 0.3);
  padding-right: 20px;
}
#dashboard .dashboard-main .upcoming-activities .body table tr th:first-child {
  padding-left: 28px;
  border-radius: 15px 0 0 15px;
}
#dashboard .dashboard-main .upcoming-activities .body table tr th:last-child {
  border-radius: 0 15px 15px 0;
}
#dashboard .dashboard-main .upcoming-activities .body table tr td {
  font-size: 16px;
  line-height: 24px;
  color: #0d0e1e;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  border-bottom: 0.5px solid #d8dde6;
}
#dashboard
  .dashboard-main
  .upcoming-activities
  .body
  table
  tr
  td
  .name-badge
  .badge {
  display: inline-block;
  margin-right: -5px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  font-size: 16px;
  color: #ffffff;
  background: #6c63ff;
  border: 1px solid #ffffff;
}
#dashboard
  .dashboard-main
  .upcoming-activities
  .body
  table
  tr
  td
  .name-badge
  .badge
  div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
#dashboard .dashboard-main .upcoming-activities .body table tr td .view {
  font-size: 14px;
  color: #00bb8d;
  cursor: pointer;
}
#dashboard .dashboard-main .upcoming-activities .body table tr td .view:hover {
  color: #00a27a;
  transition: all 0.2s ease-in;
  transition: all 0.3s ease;
}
#dashboard .dashboard-main .upcoming-activities .body table tr td:first-child {
  padding-left: 28px;
}
#dashboard .dashboard-main .upcoming-activities .body table tr:last-child td {
  border-bottom: 0;
}
#dashboard .dashboard-main .upcoming-activities .body .button-green {
  width: 195px;
  height: 42px;
}
#dashboard .dashboard-main .upcoming-activities .body .pagination-div {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
#dashboard
  .dashboard-main
  .upcoming-activities
  .body
  .pagination-div
  .pagination {
  display: flex;
}
#dashboard
  .dashboard-main
  .upcoming-activities
  .body
  .pagination-div
  .pagination
  button {
  background: #eeeeee;
  border-radius: 2px;
  font-size: 14px;
  width: 62px;
  height: 40px;
  color: #cccccc;
  border: 1px solid #eeeeee;
  cursor: pointer;
}
#dashboard
  .dashboard-main
  .upcoming-activities
  .body
  .pagination-div
  .pagination
  div {
  margin: 0 7px;
  background: #68686c;
  color: #ffffff;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#dashboard .dashboard-main .upcoming-activities.completed {
  border-top: 4px solid #00bb8d;
}
#dashboard .dashboard-main .upcoming-activities.completed table tr td {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  #dashboard .dashboard-main {
    padding: 0;
    padding-bottom: 40px;
  }
  #dashboard .dashboard-main .dashboard-hero {
    margin-top: 0;
    height: 310px;
    position: relative;
    padding: 50px 30px;
  }
  #dashboard .dashboard-main .dashboard-hero .male {
    display: none;
  }
  #dashboard .dashboard-main .dashboard-hero .female {
    display: none;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div {
    width: 100%;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div h5 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div p {
    text-align: center;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div button {
    width: 40%;
    margin-right: 30%;
    margin-left: 30%;
  }
  #dashboard .dashboard-main .cards {
    width: 100%;
    display: flex;
    padding: 0 5%;
  }
  #dashboard .dashboard-main .cards .left {
    width: 45%;
    padding: 9px;
  }
  #dashboard .dashboard-main .cards .right {
    width: 55%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  #dashboard .dashboard-main .cards .right .item {
    width: 100%;
    padding: 9px;
    height: 141px;
  }
  #dashboard .dashboard-main .cards .right .card {
    height: 123px;
    padding: 25px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #dashboard .dashboard-main .cards .right .card h6 {
    font-size: 18px;
    color: #68686c;
    margin-bottom: 15px;
  }
  #dashboard .dashboard-main .cards .right .card p {
    font-size: 14px;
    line-height: 25px;
    color: #68686c;
    opacity: 0.4;
  }
  #dashboard .dashboard-main .upcoming-activities {
    margin-left: 5%;
    margin-right: 5%;
    width: auto;
    padding: 25px 20px;
  }
  #dashboard .dashboard-main .upcoming-activities .top {
    align-items: center;
  }
  #dashboard .dashboard-main .upcoming-activities .top h5 {
    font-size: 16px;
  }
  #dashboard .dashboard-main .upcoming-activities .top button {
    font-size: 12px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
  }
  #dashboard .dashboard-main .upcoming-activities .body.empty {
    height: 363px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #dashboard .dashboard-main .upcoming-activities .body.empty img {
    margin-bottom: 25px;
  }
  #dashboard .dashboard-main .upcoming-activities .body.empty p {
    font-size: 14px;
    line-height: 25px;
    color: #68686c;
    opacity: 0.4;
  }
  #dashboard .dashboard-main .upcoming-activities .body {
    width: 100%;
  }
  #dashboard .dashboard-main .upcoming-activities .body table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 529px) {
  #dashboard .dashboard-main {
    padding: 0;
    padding-bottom: 40px;
  }
  #dashboard .dashboard-main .dashboard-hero {
    margin-top: 0;
    height: 310px;
    position: relative;
    padding: 50px 30px;
  }
  #dashboard .dashboard-main .dashboard-hero .img-3 {
    display: none;
  }
  #dashboard .dashboard-main .dashboard-hero .img-4 {
    display: none;
  }
  #dashboard .dashboard-main .dashboard-hero .male {
    display: none;
  }
  #dashboard .dashboard-main .dashboard-hero .female {
    display: none;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div {
    width: 100%;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div h5 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div p {
    text-align: center;
  }
  #dashboard .dashboard-main .dashboard-hero .text-div button {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
  }
  #dashboard .dashboard-main .cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  #dashboard .dashboard-main .cards .left {
    width: 100%;
    padding: 0;
  }
  #dashboard .dashboard-main .cards .right {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
  #dashboard .dashboard-main .cards .right .item {
    width: 100%;
    padding: 9px;
    height: 141px;
  }
  #dashboard .dashboard-main .cards .right .card {
    height: 123px;
    padding: 25px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #dashboard .dashboard-main .cards .right .card h6 {
    font-size: 18px;
    color: #68686c;
    margin-bottom: 15px;
  }
  #dashboard .dashboard-main .cards .right .card p {
    font-size: 14px;
    line-height: 25px;
    color: #68686c;
    opacity: 0.4;
  }
  #dashboard .dashboard-main .upcoming-activities {
    padding: 25px 20px;
  }
  #dashboard .dashboard-main .upcoming-activities .top {
    align-items: center;
  }
  #dashboard .dashboard-main .upcoming-activities .top h5 {
    font-size: 16px;
  }
  #dashboard .dashboard-main .upcoming-activities .top button {
    font-size: 12px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
  }
  #dashboard .dashboard-main .upcoming-activities .body.empty {
    height: 363px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #dashboard .dashboard-main .upcoming-activities .body.empty img {
    margin-bottom: 25px;
  }
  #dashboard .dashboard-main .upcoming-activities .body.empty p {
    font-size: 14px;
    line-height: 25px;
    color: #68686c;
    opacity: 0.4;
  }
  #dashboard .dashboard-main .upcoming-activities .body {
    width: 100%;
  }
  #dashboard .dashboard-main .upcoming-activities .body table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
.add-child-section {
  background: #fbfbfa;
  padding: 0 10%;
  padding-bottom: 207px;
}
.add-child-section .top {
  padding-top: 90px;
  text-align: center;
  margin-bottom: 40px;
}
.add-child-section .top h4 {
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #0d0e1e;
  margin-bottom: 9px;
}
.add-child-section .top p {
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: #68686c;
  margin-bottom: 40px;
}
.add-child-section .top .step-divs {
  display: flex;
  justify-content: center;
}
.add-child-section .top .step-divs .step {
  display: flex;
  align-items: center;
  padding: 0 50px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e1e1e1;
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: all 0.2s ease-in;
}
.add-child-section .top .step-divs .step p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
  color: #ffa300;
  margin-left: 5px;
}
.add-child-section .top .step-divs .step .check {
  display: none;
}
.add-child-section .top .step-divs .step.done {
  cursor: pointer;
  /* Safari 6.0 - 9.0 */
  filter: none;
  position: relative;
}
.add-child-section .top .step-divs .step.done img {
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.add-child-section .top .step-divs .step.done p {
  color: #68686c;
}
.add-child-section .top .step-divs .step.done .check {
  display: initial;
  /* Safari 6.0 - 9.0 */
  filter: none;
  position: absolute;
  top: -5px;
  right: 35px;
}
.add-child-section .top .step-divs .step.active {
  /* Safari 6.0 - 9.0 */
  filter: none;
  border-bottom: 2px solid #ffa300;
}
.add-child-section .form-section {
  display: flex;
  justify-content: center;
}
.add-child-section .form-section .form-div {
  width: 60%;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 60px;
  display: flex;
  justify-content: center;
}
.add-child-section .form-section .form-div form {
  width: 60%;
}
.add-child-section .form-section .form-div form label {
  position: initial;
  font-size: 14px;
  color: #68686c;
  width: 100%;
  margin-bottom: 30px;
}
.add-child-section .form-section .form-div form label input,
.add-child-section .form-section .form-div form label select {
  width: 100%;
  margin: 0;
  height: 30px;
  padding: 0;
  transition: all 0.2s ease-in;
}
.add-child-section .form-section .form-div form label input:focus,
.add-child-section .form-section .form-div form label input:active,
.add-child-section .form-section .form-div form label textarea:focus,
.add-child-section .form-section .form-div form label textarea:active,
.add-child-section .form-section .form-div form label select:focus,
.add-child-section .form-section .form-div form label select:active {
  outline: none;
  border-color: #ffa300;
}
.add-child-section .form-section .form-div form label input::placeholder {
  color: #d8d8d8;
}
.add-child-section .form-section .form-div form label textarea {
  resize: none;
  overflow: auto;
  margin: 0;
  padding-top: 0;
}
.add-child-section .form-section .form-div form label .radio-btns {
  position: relative;
  display: flex;
  margin-top: 15px;
}
.add-child-section
  .form-section
  .form-div
  form
  label
  .radio-btns
  input[type="radio"] {
  position: absolute;
  visibility: hidden;
}
.add-child-section .form-section .form-div form label .radio-btns label {
  position: relative;
  margin-bottom: 0;
  width: 86px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 100px;
  font-size: 18px;
  color: #68686c;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}
.add-child-section
  .form-section
  .form-div
  form
  label
  .radio-btns
  label
  .checked {
  position: absolute;
  left: 10px;
  display: none;
  transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
}
.add-child-section .form-section .form-div form label .radio-btns div {
  margin-right: 25px;
}
.add-child-section
  .form-section
  .form-div
  form
  label
  .radio-btns
  input[type="radio"]:checked
  ~ label {
  background: #ffa300;
  border: 0.25px solid #ffa300;
  color: #ffffff;
}
.add-child-section
  .form-section
  .form-div
  form
  label
  .radio-btns
  input[type="radio"]:checked
  ~ label
  .checked {
  display: initial;
}
.add-child-section .form-section .form-div form label:last-child {
  margin-bottom: 0;
}
.add-child-section .form-section .form-div form .dob {
  justify-content: space-between;
}
.add-child-section .form-section .form-div form .dob select {
  width: 30%;
}
.add-child-section .form-section .form-div form div h4 {
  font-size: 16px;
  line-height: 24px;
  color: #0d0e1e;
}
.add-child-section .form-section .form-div form div p {
  font-size: 14px;
  line-height: 25px;
  color: #68686c;
  margin-bottom: 30px;
}
.add-child-section .btn-div {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.add-child-section .btn-div button {
  width: 173px;
  height: 43px;
  background: #ffa300;
  border: 1px solid #ffa300;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.1);
  border-radius: 50px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
}
.add-child-section .btn-div p {
  margin-bottom: 0;
  font-size: 12px;
  color: #68686c;
}
.add-child-section .btn-div p span {
  color: #00bb8d;
}
.activities-summary {
  background: #ffffff;
  border-right: 0;
  margin-top: 1px;
  padding: 15px 8%;
  height: 102px;
}
.activities-summary .number-act {
  margin-top: 15px;
}
.activities-summary .number-act p {
  font-weight: 600;
  font-size: 20px;
  color: #0d0e1e;
}
.activities-summary .filter {
  display: flex;
}
.activities-summary .filter .labels {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.activities-summary .filter .labels span {
  color: #7b7b7e;
  font-size: 0.75rem;
}
.activities-summary .filter .labels .dropdown {
  margin-top: 7px;
}
.activities-summary .filter .labels .dropdown-toggle {
  background: #f4f4f6;
  border-radius: 50px;
  color: #68686c;
}
#activities-catalogue {
  padding-left: 8%;
  padding-right: 8%;
}
#activities-catalogue .activities-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.profile {
  padding-bottom: 65px;
  position: relative;
}
.profile .top {
  width: 100%;
  height: 85px;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.profile .top .name-badge .badge {
  height: 44px;
  display: flex;
  align-items: center;
}
.profile .top .name-badge .badge .badge {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ff6600;
  color: #ffffff;
  font-family: "GT Walsheim";
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.profile .top .name-badge .badge .img {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
}
.profile .top .name-badge .badge p {
  font-size: 14px;
  color: #1f2023;
  margin-bottom: 0;
}
.profile .top .orange-button {
  height: 31px;
  background: #f4f4f6;
  border: 1px solid #f4f4f6;
  color: #68686c;
}
.profile .add-details {
  background: #fbfbfa;
  border: 0.25px solid #e1e1e1;
  position: relative;
  padding: 41px 33px 31px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}
.profile .add-details .green-button {
  position: relative;
  z-index: 1;
}
.profile .add-details .green-button img {
  margin-right: 12px;
}
.profile .add-details .text-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
}
.profile .add-details .text {
  text-align: center;
  margin-bottom: 30px;
}
.profile .add-details .triangle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
}
.profile .no-child {
  background: #ffffff;
  width: auto;
  padding: 12px 10px;
  box-shadow: 0px 0.725702px 2.90281px rgba(13, 14, 30, 0.05);
  border-radius: 3px;
  border-top: 3px solid #ffa300;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .no-child img {
  margin-bottom: 10px;
}
.profile .no-child .text-title {
  font-size: 12px;
  margin-bottom: 5px;
}
.profile .no-child .text {
  font-size: 12px;
  margin-bottom: 12px;
}
.profile .children-section {
  padding: 12px 16px;
}
.profile .children-section .membership-card {
  background: #fbfbfa;
  border: 0.159028px solid #e1e1e1;
  border-radius: 4px;
  box-shadow: 0px 0.636111px 6.36111px rgba(13, 14, 30, 0.05);
  position: relative;
  padding: 15px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  overflow: hidden;
  margin-bottom: 12px;
}
.profile .children-section .membership-card .green-button {
  position: relative;
  z-index: 1;
}
.profile .children-section .membership-card .green-button img {
  margin-right: 12px;
}
.profile .children-section .membership-card .text-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 5px;
}
.profile .children-section .membership-card .text {
  text-align: center;
}
.profile .children-section .membership-card .triangle {
  position: absolute;
  left: 0;
  bottom: -50px;
}
.profile .children-section .children {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.profile .children-section .children .child-div {
  width: 50%;
  padding-bottom: 12px;
}
.profile .children-section .children .child-div .child {
  width: 100%;
  height: 160px;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.profile .children-section .children .child-div .child .img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-bottom: 8px;
  background-size: cover;
  background-position: center;
}
.profile .children-section .children .child-div .child .badge {
  height: 60px;
  width: 60px;
  background: #6c63ff;
  font-size: 22.4px;
  margin-bottom: 8px;
}
.profile .children-section .children .child-div .child .text-title {
  font-size: 14px;
  text-align: center;
  line-height: 1;
  margin-bottom: 18px;
}
.profile .children-section .children .child-div .child .text {
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
}
.profile .children-section .children .child-div .child .dots {
  position: absolute;
  right: 15px;
  bottom: 28px;
  height: 14px;
  width: auto;
}
.profile .children-section .children .child-div .child .options {
  background: #ffffff;
  border: 0.267252px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 7.36534px 11.7845px rgba(13, 14, 30, 0.3);
  border-radius: 4.4192px;
  position: absolute;
  right: 10px;
  bottom: 50px;
  width: 116.37px;
}
.profile .children-section .children .child-div .child .options div {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.profile .children-section .children .child-div .child .options div .text {
  margin-bottom: 0;
}
.profile
  .children-section
  .children
  .child-div
  .child
  .options
  div:nth-child(1) {
  background: rgba(244, 244, 246, 0.3);
}
.profile .children-section .children .child-div .child.add img {
  margin-bottom: 10px;
}
.profile .children-section .children .child-div .child.add .text-title {
  margin-bottom: 5px;
}
.profile .children-section .children .child-div:nth-child(odd) {
  padding-right: 6px;
}
.profile .children-section .children .child-div:nth-child(even) {
  padding-left: 6px;
}
.profile .no-contact {
  padding: 36px 16px;
}
.profile .no-contact .contact-card {
  width: 100%;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 22px;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .no-contact .contact-card img {
  margin-bottom: 10px;
}
.profile .no-contact .contact-card .text-title {
  font-size: 14px;
  text-align: center;
  line-height: 1;
  margin-bottom: 5px;
}
.profile .no-contact .contact-card .text {
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 40px;
}
.profile .no-contact .contact-card .green-button {
  width: 80%;
}
.profile .primary-contact {
  padding: 36px 5px;
}
.profile .primary-contact .contact-card {
  width: 100%;
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 25px 28px 25px 11px;
  display: flex;
  position: relative;
}
.profile .primary-contact .contact-card .badge {
  width: 44px;
  height: 44px;
  background: #0070d2;
  font-family: "GT Walsheim";
  font-size: 20px;
  margin-right: 15px;
}
.profile .primary-contact .contact-card .avatar {
  width: 44px;
  height: 44px;
  margin-right: 15px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}
.profile .primary-contact .contact-card .text-title {
  font-size: 14px;
  margin-bottom: 3px;
}
.profile .primary-contact .contact-card .text {
  font-size: 12px;
  margin-bottom: 0;
}
.profile .primary-contact .contact-card .dots {
  margin-left: auto;
}
.profile .primary-contact .contact-card .options {
  background: #ffffff;
  border: 0.267252px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 7.36534px 11.7845px rgba(13, 14, 30, 0.3);
  border-radius: 4.4192px;
  position: absolute;
  right: 12px;
  top: 68px;
  width: 116.37px;
}
.profile .primary-contact .contact-card .options div {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}
.profile .primary-contact .contact-card .options div .text {
  margin-bottom: 0;
}
.profile .primary-contact .contact-card .options div:nth-child(1) {
  background: rgba(244, 244, 246, 0.3);
}
.profile .add-child {
  width: 100%;
  background: #ffffff;
  padding: 0 16px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  overflow: auto;
  z-index: 2;
}
.profile .add-child .line {
  width: auto;
  margin: 0 -16px;
  height: 1px;
  background: #f3f4f5;
}
.profile .add-child .text-title {
  font-size: 14px;
  text-align: center;
  color: #1f2023;
  margin-top: 18px;
}
.profile .add-child label,
.profile .add-child .label {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.profile .add-child label .text,
.profile .add-child .label .text {
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
}
.profile .add-child .img-label {
  text-align: center;
}
.profile .add-child .img-label .text {
  width: 100%;
  text-align: center;
  color: #737373;
}
.profile .add-child .img-label img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile .add-child .select-label {
  display: flex;
  justify-content: space-between;
  background: rgba(244, 244, 246, 0.2);
  position: relative;
}
.profile .add-child .select-label select {
  border-radius: 0;
  width: 30%;
  height: 42px;
  padding-top: 16px;
  padding-left: 0;
  background: none;
}
.profile .add-child .select-label select:invalid {
  color: #e1e1e1;
}
.profile .add-child .select-label .error-text {
  bottom: -14px;
}
.profile .add-child input {
  height: 42px;
  padding-top: 16px;
  padding-bottom: 4px;
}
.profile .add-child .button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .add-child .button-div .green-button {
  width: 80%;
  margin-top: 30px;
}
.profile .add-child .button-div h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffa300;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 0;
}
.profile .add-contact {
  width: 100%;
  background: #ffffff;
  margin-top: 7px;
  padding: 0 16px;
  padding-bottom: 100px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
}
.profile .add-contact .text-title.edit-text {
  font-size: 14px;
  text-align: center;
  color: #1f2023;
  margin-top: 50px;
  margin-bottom: 50px;
}
.profile .add-contact label,
.profile .add-contact .label {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.profile .add-contact label .text,
.profile .add-contact .label .text {
  font-size: 12px;
  margin-bottom: 0;
  position: absolute;
}
.profile .add-contact label select,
.profile .add-contact .label select {
  border-radius: 0;
  background: none;
}
.profile .add-contact .img-label {
  text-align: center;
}
.profile .add-contact .img-label .text {
  width: 100%;
  text-align: center;
  color: #737373;
}
.profile .add-contact .img-label img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.profile .add-contact input {
  height: 42px;
  padding-top: 18px;
  padding-bottom: 5px;
}
.profile .add-contact .button-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile .add-contact .button-div .green-button {
  width: 80%;
  margin-top: 30px;
}
.profile .add-contact .button-div h5 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffa300;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 0;
}
.profile .logout-div {
  text-align: center;
}
.profile .logout-div .green-button {
  width: 140px;
  background: #ffffff;
  color: #ef3d4b;
  margin-bottom: 20px;
  margin-top: 20px;
}
.profile .logout-div .green-button:active {
  background: #ef3d4b;
  color: #ffffff;
}
.profile .popup .text {
  font-size: 16px;
  margin-top: 20px;
}
.profile .popup .modalBtn {
  display: flex;
  justify-content: space-between;
}
.profile .popup .modalBtn .green-button {
  width: 100px;
  margin: 20px 15px 10px 15px;
}
.profile .popup .modalBtn .green-button.outline {
  border: 1px solid #ef3d4b;
  color: #ef3d4b;
  background: transparent;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  #activities-catalogue .activities-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 529px) {
  #activities-catalogue .activities-wrap {
    grid-template-columns: 1fr;
  }
}
#activities-catalogue a {
  color: inherit;
}
#activities-catalogue .act-card {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
}
#activities-catalogue .act-card .act-image {
  height: 150px;
}
#activities-catalogue .act-card .act-image img {
  border-radius: 4px 4px 0 0;
  height: 150px;
  width: 100%;
}
#activities-catalogue .act-card .act-card-details {
  border-radius: 0 0 4px 4px;
  padding: 15px;
}
#activities-catalogue .act-card .act-card-details h4 {
  color: #0d0e1e;
  font-size: 1.125rem;
  margin-bottom: 20px;
}
#activities-catalogue .act-card .act-card-details p {
  color: #68686c;
  font-size: 0.75rem;
}
#activities-catalogue .act-card .act-card-details p .fa-circle {
  font-size: 0.25rem;
  margin: -5px 5px 0;
}
#activities-catalogue .act-card .act-card-details .card-line {
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border: 0.5px solid rgba(216, 221, 230, 0.75);
}
#activities-catalogue .act-card .act-card-details li {
  display: inline-block;
  border: 1px solid #f4f4f6;
  background: #f4f4f6;
  padding: 5px 12px;
  font-size: 0.75rem;
  border-radius: 50px;
  margin-right: 10px;
}
#activities-catalogue .act-card .act-card-details li img {
  margin-right: 5px;
}
#activities-catalogue .load-more {
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
}
#activities-catalogue .load-more a {
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #dfe3e8;
  border-radius: 50%;
  box-sizing: border-box;
  border-radius: 31px;
  padding: 10px 20px;
}
#activities-catalogue .load-more button {
  width: 195px;
  height: 42px;
  font-weight: 600;
  font-size: 14px;
}
#single-activity .act-wrap {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 15px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  #single-activity .act-wrap {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 529px) {
  #single-activity .act-wrap {
    grid-template-columns: 1fr;
  }
}
#single-activity .act-wrap .act-carousel {
  height: 390px;
  box-sizing: border-box;
  box-shadow: 0px 1px 10px rgba(13, 14, 30, 0.05);
}
@media only screen and (max-width: 529px) {
  #single-activity .act-wrap .act-carousel {
    height: auto;
  }
}
#single-activity .act-wrap .act-carousel img {
  border-radius: 4px;
  width: 100%;
  background-size: cover;
}
#single-activity .act-wrap .act-detail {
  background: #ffffff;
  border: 0.25px solid rgba(225, 225, 225, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 10px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 25px;
}
#single-activity .act-wrap .act-detail h4 {
  color: #0d0e1e;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
#single-activity .act-wrap .act-detail .line {
  border: 0.5px solid rgba(216, 221, 230, 0.33);
  margin: 15px 0 25px;
}
#single-activity .act-wrap .act-detail .quick-act-info {
  display: flex;
  margin-bottom: 25px;
}
#single-activity .act-wrap .act-detail .quick-act-info > div {
  padding: 10px;
  background: #ffffff;
  border: 0.5px solid #d8dde6;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
}
#single-activity .act-wrap .act-detail .quick-act-info > div p {
  color: #0d0e1e;
  margin-bottom: 0px;
}
#single-activity .act-wrap .act-detail .quick-act-info > div span {
  color: #68686c;
}
#users-page .top {
  display: flex;
  align-items: center;
}
#users-page .top h3 {
  font-weight: 600;
  font-size: 20px;
  flex-grow: 1;
  color: #0d0e1e;
}
#users-page .top .select {
  height: 33px;
  padding: 0 15px;
  font-size: 14px;
  background: #f4f4f6;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #68686c;
  margin-right: 50px;
  cursor: pointer;
}
#users-page .top .button-outline-green {
  padding: 0 10px;
  height: 42px;
  margin-top: 20px;
  min-width: 142px;
}
#users-page .top .button-outline-green img {
  margin-right: 14px;
}
#users-page .users-container {
  padding: 50px 8%;
}
#users-page .users-container .user-tag {
  width: 103px;
  height: 26px;
  border-radius: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#users-page .users-container .parent {
  background: rgba(128, 221, 198, 0.1);
  color: #00bb8d;
}
#users-page .users-container .educator {
  background: rgba(255, 163, 0, 0.1);
  color: #ffa300;
}
#users-page .users-container .delete-button {
  background: transparent;
  border: 1px solid #ef3d4b;
  padding: 0 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  border-radius: 31px;
  height: 38px;
  color: #ef3d4b;
  font-size: 14px;
}
#users-page .delete-modal {
  padding: 40px 30px;
  max-width: 356px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#users-page .delete-modal h5 {
  margin-bottom: 15px;
  font-size: 16px;
  color: #1f2023;
  text-transform: uppercase;
}
#users-page .delete-modal p {
  font-size: 14px;
  color: #0d0e1e;
  text-align: center;
  margin-bottom: 15px;
}
#users-page .delete-modal .btn-div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
#users-page .delete-modal .btn-div button {
  padding: 0 24px;
  height: 38px;
  background: #00bb8d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  outline: 0 !important;
}
#users-page .delete-modal .btn-div button.outline {
  border: 1px solid #ef3d4b;
  color: #ef3d4b;
  background: transparent;
  margin-right: 20px;
}
#users-page .delete-modal .loader {
  height: 50px;
  margin-bottom: -30px;
}
#users-page .delete-modal .success_icon {
  margin-bottom: 20px;
}
#users-page .delete-modal span {
  margin-top: 15px;
}

.edit-modal form {
  box-sizing: border-box;

  position: absolute;
  width: 595px;
  height: 695px;
  left: 610px;
  top: 227px;

  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
}

.view-profile-image {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 72px;
  top: 100px;
  background: #a4a1a1;
  background-blend-mode: normal;
}

.view-profile-icon {
  position: absolute;
  right: 56.65%;
  bottom: 40.94%;
  left: -15px;
  top: -20px;
}

.add-child-button {
  box-sizing: border-box;
  position: absolute;
  width: 251px;
  height: 48px;
  left: 155px;
  top: 720px;
  background: #00bb8d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
}

.add-child-modal form {
  box-sizing: border-box;

  position: absolute;
  width: 595px;
  height: 795px;
  left: 610px;
  top: 227px;

  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
}

.add-child-name {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.add-child-input-text {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;

  color: #131212;
}

.add-date-of-birth {
  position: absolute;
  width: 386px;
  height: 50px;
  left: 80px;
  top: 317px;

  background: rgba(244, 244, 246, 0.2);
}

.add-date-of-birth label {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.add-date-of-birth input {
  width: 90px;
  margin-right: 35px;
}
.add-date-of-birth select {
  width: 85px;
  margin-right: 35px;
}

.add-gender {
  position: absolute;
  width: 148px;
  height: 18px;
  left: 80px;
  top: 417px;
}

.add-gender label {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.boy-radio-position input {
  position: absolute;
  width: 21px;
  height: 18px;
  top: 27px;
}

.boy-radio-position input:checked + label {
  color: #ffa300;
}

.boy-radio-position label {
  margin-left: 30px;
  top: 35px;
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.girl-radio-position input {
  position: absolute;
  width: 21px;
  height: 18px;
  top: 29px;
  left: 120px;
}

.girl-radio-position input:checked + label {
  color: #ffa300;
}

.girl-radio-position label {
  margin-left: 30px;
  left: 120px;
  top: 37px;
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.add-school {
  position: absolute;
  width: 386px;
  height: 50px;
  left: 81px;
  top: 521px;
  background: rgba(244, 244, 246, 0.2);
  margin-bottom: 20px;
}

.add-school label {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.add-special-needs {
  position: absolute;
  width: 386px;
  height: 50px;
  left: 81px;
  top: 621px;
  background: rgba(244, 244, 246, 0.2);
  margin-bottom: 20px;
}

.add-special-needs label {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 150%;

  color: #a09c9c;
}

.edit-first-name {
  position: absolute;
  width: 176.81px;
  height: 50px;
  left: 81px;
  top: 221px;
}

.edit-first-name-label .edit-first-name {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;

  color: #c4c4c4;
}

.edit-first-name-input .edit-first-name {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  color: #333333;
}

.edit-last-name {
  position: absolute;
  width: 176.81px;
  height: 50px;
  left: 300.19px;
  top: 221px;

  background: rgba(244, 244, 246, 0.2);
}

.edit-last-name-label .edit-last-name {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #c4c4c4;
}

.edit-last-name-input .edit-last-name {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  color: #333333;
}

.edit-phone-number {
  position: absolute;
  width: 177px;
  height: 50px;
  left: 80px;
  top: 321px;

  background: rgba(244, 244, 246, 0.2);
}
.edit-phone-number-label .edit-phone-number {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #c4c4c4;
}

.edit-phone-number-input .edit-phone-number {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #333333;
}

.edit-country {
  position: absolute;
  width: 177px;
  height: 50px;
  left: 300px;
  top: 321px;

  background: rgba(244, 244, 246, 0.2);
}

.edit-country-label .edit-country {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #c4c4c4;
}

.edit-country-select .edit-country {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  color: #333333;
}

.edit-email {
  position: absolute;
  width: 386px;
  height: 50px;
  left: 81px;
  top: 421px;
  background: rgba(244, 244, 246, 0.2);
}

.edit-email-label .edit-email {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #c4c4c4;
}

.edit-email-input .edit-email {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  color: #333333;
}

.edit-address {
  position: absolute;
  width: 386px;
  height: 50px;
  left: 81px;
  top: 521px;

  background: rgba(244, 244, 246, 0.2);
}

.edit-address-label .edit-address {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #c4c4c4;
}

.edit-address-input .edit-address {
  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  color: #333333;
}

.edit-button {
  box-sizing: border-box;
  position: absolute;
  width: 251px;
  height: 48px;
  left: 155px;
  top: 620px;
  background: #00bb8d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
}

.edit-button-text {
  position: absolute;
  width: 32px;
  height: 20px;
  left: 105px;
  top: 15px;

  font-family: "GT Walsheim";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
}

#view-profile .edit-modal .profile-image {
  position: absolute;
  left: 46.94%;
  right: 46.65%;
  top: 24.91%;
  bottom: 67.94%;
  background: #e6e6e6;
}

#view-profile .delete-modal {
  padding: 40px 30px;
  max-width: 356px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#view-profile .delete-modal h5 {
  margin-bottom: 15px;
  font-size: 16px;
  color: #1f2023;
  text-transform: uppercase;
}
#view-profile .delete-modal p {
  font-size: 14px;
  color: #0d0e1e;
  text-align: center;
  margin-bottom: 15px;
}
#view-profile .delete-modal .btn-div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
#view-profile .delete-modal .btn-div button {
  padding: 0 24px;
  height: 38px;
  background: #00bb8d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 31px;
  font-size: 14px;
  color: #ffffff;
  border: 0;
  outline: 0 !important;
}
#view-profile .delete-modal .btn-div button.outline {
  border: 1px solid #ef3d4b;
  color: #ef3d4b;
  background: transparent;
  margin-right: 20px;
}
#view-profile .delete-modal .loader {
  height: 50px;
  margin-bottom: -30px;
}
#view-profile .delete-modal .success_icon {
  margin-bottom: 20px;
}
#view-profile .delete-modal span {
  margin-top: 15px;
}

#view-session .top {
  display: flex;
  align-items: center;
}
#view-session .top h3 {
  font-weight: 600;
  font-size: 20px;
  color: #0d0e1e;
}
#view-session .view-session-main {
  padding: 50px 8%;
  display: grid;
  grid-template-columns: 2.5fr 2fr 1fr;
  grid-gap: 25px;
}
#view-session .view-session-main .view-card {
  width: 100%;
  background: #ffffff;
  border: 0.25px solid rgba(225, 225, 225, 0.5);
  border-radius: 4px;
  padding: 35px;
}
#view-session .view-session-main .view-card h4 {
  font-size: 24px;
  color: #0d0e1e;
}
#view-session .view-session-main .view-card p {
  font-size: 16px;
  color: #68686c;
}
#view-session .view-session-main .view-card .card-line {
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border: 0.5px solid rgba(216, 221, 230, 0.75);
}
#view-session .view-session-main .view-card li {
  display: inline-block;
  border: 1px solid #f4f4f6;
  background: #f4f4f6;
  padding: 5px 12px;
  font-size: 0.75rem;
  border-radius: 50px;
  margin-right: 10px;
}
#view-session .view-session-main .view-card li img {
  margin-right: 5px;
}
#view-session .view-session-main .view-card .bottom-row {
  display: flex;
  justify-content: space-between;
}
#view-session .view-session-main .view-card .bottom-row .session-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#view-session .view-session-main .view-card .bottom-row .session-info h5 {
  font-weight: 600;
  font-size: 14px;
  color: #0d0e1e;
  margin-bottom: 5px;
}
#view-session
  .view-session-main
  .view-card
  .bottom-row
  .session-info
  .info-tag {
  padding: 0 20px;
  border: 0.5px solid #d8dde6;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #68686c;
}
#view-session
  .view-session-main
  .view-card
  .bottom-row
  .session-info
  .info-tag
  .name-badge
  .badge {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff6600;
  color: #ffffff;
  font-size: 12px;
  margin-right: 5px;
}
#view-session .view-session-main .section:last-child .view-card {
  border: 0.5px solid rgba(255, 163, 0, 0.44);
  padding: 35px 45px;
}
#view-session .view-session-main .section:last-child .view-card h4 {
  text-align: center;
  font-size: 18px;
  color: #0d0e1e;
  font-weight: 600;
  margin-bottom: 33px;
}
#view-session .view-session-main .section:last-child .view-card .educator-name {
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  height: 32px;
  padding: 0 6px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .educator-name
  p {
  margin: 0 5px;
  font-size: 12px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .educator-name
  img {
  cursor: pointer;
  height: 12px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .educator-name
  .badge {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  color: #ffffff;
  background: #ff6600;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator {
  border: 0.5px solid #d8dde6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 18px;
  margin-bottom: 50px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  h5 {
  margin: 0;
  margin-bottom: 10px;
  color: #68686c;
  font-size: 14px;
  font-weight: normal;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div {
  display: flex;
  align-items: center;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .educator-name {
  margin-right: auto;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .toggle-comments {
  cursor: pointer;
  display: flex;
  align-items: center;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .toggle-comments
  p {
  font-size: 14px;
  color: #00bb8d;
  margin: 0;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .toggle-comments
  img {
  margin-left: 5px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .toggle-comments
  img.spin {
  transform: rotate(180deg);
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .time-left {
  margin-top: -20px;
  background: #f5ecdd;
  border-radius: 7px;
  height: 50px;
  min-width: 54px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .time-left
  p:first-child {
  color: #ffa300;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 22px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .flex-div
  .time-left
  p:last-child {
  color: #ffa300;
  font-size: 7px;
  margin-bottom: 0;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .completed-educator
  .comments {
  padding: 10px;
  margin-top: 20px;
  background: rgba(244, 244, 246, 0.2);
  font-size: 14px;
  color: #68686c;
}
#view-session .view-session-main .section:last-child .view-card label {
  width: 100%;
  margin-bottom: 50px;
  position: relative;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .select-div {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ffa300;
  height: 42px;
  display: flex;
  align-items: center;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .select-div
  img {
  margin-left: auto;
  height: 10px;
  cursor: pointer;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .educator-options {
  width: 100%;
  max-height: 133px;
  overflow-y: scroll;
  padding: 20px 24px;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(33, 43, 54, 0.08),
    0px 0px 0px rgba(6, 44, 82, 0.1);
  position: absolute;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .educator-options
  .educator {
  display: flex;
  margin-bottom: 18px;
  font-size: 14px;
  color: #68686c;
  cursor: pointer;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .educator-options
  .educator
  .badge {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  color: #ffffff;
  background: #ff6600;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .educator-options
  .educator:last-child {
  margin-bottom: 0;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  label
  .error-text {
  font-size: 10px;
  text-align: initial;
  color: #bc3e06;
  position: absolute;
}
#view-session .view-session-main .section:last-child .view-card .label {
  margin-bottom: 50px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .label
  p:first-child {
  color: #68686c;
  font-size: 14px;
  margin-bottom: 5px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .label
  p:last-child {
  color: #0d0e1e;
  font-weight: 500;
}
#view-session .view-session-main .section:last-child .view-card .button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#view-session .view-session-main .section:last-child .view-card .button-div img,
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .button-div
  .error-text {
  position: absolute;
  height: 40px;
  top: -40px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .button-div
  .error-text {
  font-size: 12px;
  color: #bc3e06;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .button-div
  .button-outline-grey {
  margin-right: 30px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .button-div
  button {
  height: 43px;
  width: 162px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .button-div
  button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
#view-session .view-session-main .section:last-child .view-card .media-div {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .media-div
  .file {
  width: 100%;
  height: 86px;
}
#view-session
  .view-session-main
  .section:last-child
  .view-card
  .media-div
  .file
  img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.admin-profile .top {
  display: flex;
  align-items: center;
}
.admin-profile .top h3 {
  font-weight: 600;
  font-size: 18px;
  color: #0d0e1e;
}
.admin-profile .profile-details {
  padding: 50px 8%;
  display: grid;
  grid-template-columns: 336px 600px;
  grid-gap: 96px;
}
.admin-profile .profile-details .tab-buttons {
  width: 100%;
}
.admin-profile .profile-details .tab-buttons .tab-button {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  background: #ffffff;
}
.admin-profile .profile-details .tab-buttons .tab-button p {
  margin-bottom: 0;
  flex-grow: 1;
  font-weight: 600;
  font-size: 14px;
  color: #68686c;
}
.admin-profile .profile-details .tab-buttons .tab-button img:first-child {
  margin-right: 20px;
}
.admin-profile .profile-details .tab-buttons .tab-button.active {
  background: rgba(255, 163, 0, 0.03);
}
.admin-profile .profile-details .tab-buttons .tab-button.active p {
  color: #ffa300;
}
.admin-profile .profile-details .tab-buttons .tab-button:last-child {
  cursor: pointer;
  margin-top: 12px;
}
.admin-profile .profile-details .profile-information {
  background: #ffffff;
  border: 0.25px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(13, 14, 30, 0.05);
  border-radius: 4px;
  padding: 30px 45px;
}
.admin-profile .profile-details .profile-information .name-display {
  display: flex;
  width: 100%;
  padding-bottom: 18px;
  border-bottom: 0.5px solid #d8dde6;
  margin-bottom: 25px;
}
.admin-profile
  .profile-details
  .profile-information
  .name-display
  .name-location {
  display: flex;
  flex-direction: column;
}
.admin-profile
  .profile-details
  .profile-information
  .name-display
  .name-location
  p:first-child {
  margin: 0;
  font-size: 24px;
  color: #0d0e1e;
}
.admin-profile
  .profile-details
  .profile-information
  .name-display
  .name-location
  p:last-child {
  margin: 0;
  font-size: 14px;
  color: #ffa300;
  opacity: 0.7;
}
.admin-profile .profile-details .profile-information .label {
  margin-bottom: 28px;
}
.admin-profile .profile-details .profile-information .label p:first-child {
  color: #68686c;
  font-size: 14px;
  margin-bottom: 5px;
}
.admin-profile .profile-details .profile-information .label p:last-child {
  color: #0d0e1e;
  font-weight: 500;
}
.sessions-page .top {
  display: flex;
  align-items: center;
}
.sessions-page .top h3 {
  font-weight: 600;
  font-size: 18px;
  color: #0d0e1e;
  flex-grow: 1;
}
.sessions-page .sessions-content {
  padding: 50px 8%;
}
.sessions-page .sessions-content .table-lists.completed {
  border-top: 4px solid #00bb8d;
}
.session-empty-state {
  display: flex;
  justify-content: center;
  padding: 50px 20px;
}
.create-session-page .top {
  display: flex;
  align-items: center;
}
.create-session-page .top p {
  margin: 0;
  margin-right: 10px;
  color: #68686c;
}
.create-session-page .top p:nth-child(2) {
  font-size: 20px;
}
.create-session-page .create-session-content {
  padding: 50px 8%;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: 40px;
}
.create-session-page .create-session-content .tab-buttons {
  width: 100%;
}
.create-session-page .create-session-content .tab-buttons .tab-button {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  background: #ffffff;
  cursor: pointer;
}
.create-session-page .create-session-content .tab-buttons .tab-button p {
  margin-bottom: 0;
  flex-grow: 1;
  font-weight: 600;
  font-size: 14px;
  color: #68686c;
}
.create-session-page
  .create-session-content
  .tab-buttons
  .tab-button
  img:first-child {
  margin-right: 20px;
}
.create-session-page .create-session-content .tab-buttons .tab-button.active {
  background-color: rgba(255, 163, 0, 0.03);
  transition: background-color 500ms linear;
}
.create-session-page .create-session-content .tab-buttons .tab-button.active p {
  color: #ffa300;
}
.create-session-page .create-session-content .create-session-forms {
  width: 100%;
  background-color: #ffffff;
}
.create-session-page .create-session-content .create-session-forms .steps {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  height: 70px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .steps
  .step {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .steps
  .step
  .number-div {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #737373;
  color: #ffffff;
  font-weight: 500;
  height: 22px;
  width: 22px;
  margin-right: 8px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .steps
  .step.active {
  border-bottom: 4px solid #ffa300;
  color: #ffa300;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .steps
  .step.active
  .number-div {
  background: #ffa300;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .steps
  .step.previous {
  color: #00bb8d;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .steps
  .step.previous
  .number-div {
  background: #00bb8d;
}
.create-session-page .create-session-content .create-session-forms .main-form {
  padding: 40px 30px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
  color: #ffffff;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option
  .check {
  width: 20px;
  height: 20px;
  border: 0.5px solid #d8d8d8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option
  .check
  .inner {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffaa12;
  display: none;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option:hover {
  border: 2px solid #d8d8d8;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option.selected {
  border: 2px solid #ffaa12;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option.selected
  .check {
  border: 0.5px solid #ffaa12;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .option.selected
  .check
  .inner {
  background: #ffaa12;
  display: initial;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input {
  width: 300px;
  border-bottom: 1px solid #68686c;
  position: relative;
  margin-bottom: 40px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  span {
  color: #c4c4c4;
  display: block;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  input {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .selected-parent {
  display: inline-flex;
  align-items: center;
  height: 32px;
  border-radius: 15px;
  padding: 0 6px;
  border: 1px solid #d8d8d8;
  margin-bottom: 5px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .selected-parent
  .badge {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background: #ff6600;
  font-size: 10px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .selected-parent
  p {
  margin: 0;
  font-size: 12px;
  color: #68686c;
  margin-right: 5px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .selected-parent
  img {
  cursor: pointer;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .auto-complete {
  position: absolute;
  max-height: 133px;
  padding: 12px 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(33, 43, 54, 0.08),
    0px 0px 0px rgba(6, 44, 82, 0.1);
  top: 65px;
  overflow-y: scroll;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .auto-complete
  .parent {
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  cursor: pointer;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .auto-complete
  .parent
  .badge {
  height: 30px;
  width: 30px;
  margin-right: 8px;
  background: #ff6600;
  font-size: 12px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .auto-complete
  .parent
  p {
  margin: 0;
  color: #68686c;
  font-size: 14px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .search-input
  .auto-complete
  .parent:hover {
  background: #d8d8d8;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div {
  margin-bottom: 45px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  span {
  display: block;
  color: #c4c4c4;
  margin-bottom: 10px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div {
  width: 300px;
  height: 58px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-top: 18px;
  cursor: pointer;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div
  .check {
  margin: 0 16px;
  width: 20px;
  height: 20px;
  border: 0.5px solid #d8d8d8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div
  .check
  .inner {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffaa12;
  display: none;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div
  .line {
  height: 40px;
  width: 1px;
  background-color: #d8d8d8;
  margin-right: 13px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div
  .badge {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background: #54698d;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: normal;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div
  p {
  margin: 0;
  font-size: 14px;
  color: #68686c;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div:hover {
  border: 2px solid #d8d8d8;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div.selected {
  border: 2px solid #ffaa12;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div.selected
  .check {
  border: 0.5px solid #ffaa12;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div.selected
  .check
  .inner {
  background: #ffaa12;
  display: initial;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .child-div:first-child {
  margin-top: 0;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .children-div
  .info-div {
  width: 300px;
  background: #fdf7ec;
  color: #ffa300;
  border-radius: 0px 0px 4px 4px;
  padding: 12px 16px;
  font-size: 12px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  button {
  width: 173px;
  height: 43px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .button-orange:disabled {
  cursor: not-allowed;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-top {
  border-bottom: 0.5px solid #d8d8d8;
  padding: 12px 20px;
  display: flex;
  align-items: center;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-top
  .activity-name
  h5 {
  font-weight: normal;
  font-size: 14px;
  line-height: 164.22%;
  color: #1d1e1c;
  margin: 0;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-top
  .activity-name
  p {
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #68686c;
  opacity: 0.7;
  margin: 0;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-bottom {
  padding: 16px 20px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-bottom
  p {
  margin: 0;
  font-size: 14px;
  line-height: 164.22%;
  color: #68686c;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-bottom
  .tags {
  display: flex;
  margin-top: 5px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-bottom
  .tags
  .tag {
  display: flex;
  align-items: center;
  background: #f4f4f6;
  border-radius: 50px;
  height: 24px;
  color: #68686c;
  font-size: 12px;
  padding: 0 8px;
  margin-right: 10px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .activity-bottom
  .tags
  .tag
  img {
  margin-right: 5px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .btn-div {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  p {
  margin-bottom: 15px;
  color: #c4c4c4;
  font-size: 14px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content {
  width: 305px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .duration {
  margin-bottom: 40px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .duration
  .options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .duration
  .options
  .option {
  height: 58px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .duration
  .options
  .option
  .check {
  margin: 0 16px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .duration
  .options
  .option
  p {
  margin-bottom: 0;
  margin-left: 6px;
  font-size: 14px;
  color: #68686c;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .b-m {
  margin-bottom: 30px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .date {
  margin-bottom: 40px;
  background: rgba(244, 244, 246, 0.2);
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .date
  .date-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 26px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .date
  .date-inputs
  input,
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .date
  .date-inputs
  select {
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 0.5px solid #68686c;
  background: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  font-size: 12px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .date
  .date-inputs
  input:focus,
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .date
  .date-inputs
  select:focus {
  outline: none;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .time {
  margin-bottom: 40px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .content
  .time
  .input-style {
  width: 50%;
  height: 42px;
  padding: 12px 10px;
  background: rgba(244, 244, 246, 0.2);
  border: 0;
  border-bottom: 0.5px solid #68686c;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  font-size: 12px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-two
  .btn-div {
  display: flex;
  justify-content: space-between;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-three
  .title {
  font-weight: normal;
  font-size: 14px;
  line-height: 175.75%;
  color: #c4c4c4;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-three
  .activities {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  max-height: 420px;
  overflow-y: scroll;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-three
  .activities
  .option {
  cursor: pointer;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-three
  .activities
  .option
  .check {
  margin-right: 16px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-four
  .content {
  width: 300px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .step-four
  .content
  .option {
  margin-bottom: 40px;
}
.create-session-page
  .create-session-content
  .create-session-forms
  .main-form
  .error-text {
  font-size: 10px;
  text-align: center;
  color: #bc3e06;
  position: absolute;
  margin: 0;
}

